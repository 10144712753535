export default function makeHeaderLogic() {
    const header = document.getElementById('js-header'),
        headerMobileBar = document.getElementById('js-header-mobile-menu-bar'),
        headerButtonMobile = document.getElementsByClassName('js-header-mobile-menu-toggler'),
        headerBoxMobile = document.getElementById('js-header-box-mobile');

    // Desktop
    if (header) {
        const headerNav = header.querySelector('#js-header-nav');

        window.addEventListener('scroll', () => {
            if (window.scrollY <= 100) {
                headerNav.classList.remove('is-scrolling');
            } else {
                headerNav.classList.add('is-scrolling');
            }
        });
    }

    // Mobile
    if (headerMobileBar && headerButtonMobile.length && headerBoxMobile) {

        let lastScrollPosition = 0;
        const scrollBuffer = 50;

        // Bar logic
        window.addEventListener('scroll', () => {
            if (lastScrollPosition + scrollBuffer < window.scrollY) {
                headerMobileBar.classList.add('is-hidden');
                lastScrollPosition = window.scrollY;
            } else if (window.scrollY < lastScrollPosition) {
                headerMobileBar.classList.remove('is-hidden');
                lastScrollPosition = window.scrollY;
            }
        });

        // Menu logic
        for (const iterator of headerButtonMobile) {
            iterator.addEventListener('click', () => {
                if (!headerBoxMobile.classList.contains('is-opened')) {
                    headerBoxMobile.classList.add('is-opened');
                    headerMobileBar.classList.add('is-hidden');
                    document.body.classList.add('overflow-hidden');
                } else {
                    headerBoxMobile.classList.remove('is-opened');
                    headerMobileBar.classList.remove('is-hidden');
                    document.body.classList.remove('overflow-hidden');
                }
            });
        }
    }
}
