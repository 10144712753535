import Cookies from "js-cookie";

/**
 * Logic for promo box
 */
export default function makePromoBoxLogic() {
    const promoBox = document.getElementById('js-promo-box');
    let promoBoxWasClosed = Cookies.get('dreamscape-promo-box-closed');

    if (promoBox && !promoBoxWasClosed) {
        const promoBoxCloseButton = document.getElementById('js-promo-box-close-button');

        // Close promo box when button close was clicked
        promoBoxCloseButton.addEventListener('click', () => {
            const howManyMinutesToExpire = 2; // Time in minutes
            const calculatedExpireDate = new Date(new Date().getTime() + howManyMinutesToExpire * 60 * 1000);

            Cookies.set('dreamscape-promo-box-closed', 'true', {
                expires: calculatedExpireDate ?? 1
            });
            promoBox.classList.remove('is-visible');
            promoBoxWasClosed = true;
        })

        // Show promo box when scrolling
        window.addEventListener('scroll', () => {
            if (300 <= window.scrollY && !promoBoxWasClosed) {
                promoBox.classList.add('is-visible');
            }
        });
    }
}