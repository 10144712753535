import Aos from 'aos';
import 'lightbox2';
import '../../../../../../node_modules/bootstrap/js/src/modal';
import makeHeaderLogic from './functions/header';
import makeHoversLogic from './functions/hovers';
import makePromoBoxLogic from './functions/promo-box';

var documentReady = function () {
    console.log('DOM Loaded');

    // Zmienna globalna przenosząca tłumaczenia. Konfigurowalna w config.php.
    console.log(i18n);

    // RxJS working
    // fromEvent(document, "click").subscribe(() => {
    //     test();
    // });

    makeHeaderLogic();
    makeHoversLogic();
    makePromoBoxLogic();

    Aos.init({
        once: true,
    });
};

if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
