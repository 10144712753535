/**
 * Init for all JavaScript Hovers
 */
export default function makeHoversLogic() {
    makeHoverEffects('js-nav-link');
    makeHoverEffects('js-room-item');
    makeHoverEffects('js-faq-item');
    makeHoverEffects('js-gallery-item');
}

/**
 * [...]
 */
function makeHoverEffects(groupClassName) {
    const menuItems = document.getElementsByClassName(groupClassName);

    if (menuItems) {
        for (const element of menuItems) {
            element.addEventListener('mouseenter', event => {
                for (const loopingElement of menuItems) {
                    if (event.target != loopingElement) {
                        loopingElement.classList.add('is-darker');
                    }
                }
            });

            element.addEventListener('mouseleave', event => {
                for (const element of menuItems) {
                    element.classList.remove('is-darker');
                }
            });
        }
    }
}
